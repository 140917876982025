import {
  APPEND_NOTIFICATION,
  SET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_PAYLOAD,
  UPDATE_VIEWMORE_LOADER,
  SET_HOME_NOTIFICATIONS,
  UPDATE_HOME_NOTIFICATION,
  UPDATE_HOME_NOTIFICATION_PAYLOAD,
  UPDATE_HOME_VIEWMORE_LOADER,
  SET_LT_SEARCH_DATA,
} from "../Types";

import { viewMoreNotificationsHanlder } from "../Utils/notificationsUtils";

const INITIAL_STATE = {
  notifications: [],
  listPage: 1,
  fetchMore: false,
  listCount: 0,
  viewMoreLoader: false,

  homeNotifications: [],
  homeListPage: 1,
  homeFetchMore: false,
  homeListCount: 0,
  homeViewMoreLoader: false,
};
const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPEND_NOTIFICATION:
      let _pnotifications = [...state.notifications];
      let _pHomeNotifications = [...state.homeNotifications];
      const filteredResult = _pnotifications.filter((n) => n.id === action.payload.id);
      const homeFiletedResult = _pHomeNotifications.filter((n) => n.id === action.payload.id);
      if (filteredResult.length === 0 && homeFiletedResult.length === 0) {
        _pnotifications.unshift(action.payload);
        _pHomeNotifications.unshift(action.payload);
        return {
          ...state,
          notifications: _pnotifications,
          homeNotifications: _pHomeNotifications,
        };
      } else {
        return { ...state };
      }

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload.notifications],
        homeListCount: action.payload.count,
        fetchMore: viewMoreNotificationsHanlder(action.payload, [...action.payload.notifications]),
      };

    case SET_HOME_NOTIFICATIONS:
      return {
        ...state,
        homeNotifications: [...action.payload.notifications],
        homeListCount: action.payload.count,
        homeFetchMore: viewMoreNotificationsHanlder(action.payload, [...action.payload.notifications]),
      };

    case UPDATE_NOTIFICATION:
      let _notifications = [...state.notifications];
      const index = _notifications.findIndex((n) => n.id === action.payload.id);
      if (index > -1) {
        _notifications[index] = action.payload;
      }
      return { ...state, notifications: [..._notifications] };

    case UPDATE_HOME_NOTIFICATION:
      let _homeNotificaitons = [...state.homeNotifications];
      const _updatedHomeNotificaitons = _homeNotificaitons.filter((n) => n.id !== action.payload.id);
      return { ...state, homeNotifications: _updatedHomeNotificaitons };

    case UPDATE_NOTIFICATION_PAYLOAD:
      let prevPageNumber = state.listPage;
      prevPageNumber = prevPageNumber + 1;
      let _oldNotification = [...state.notifications];
      const _updatedNotifications = [..._oldNotification, ...action.payload.notifications];
      return {
        ...state,
        notifications: [..._updatedNotifications],
        fetchMore: viewMoreNotificationsHanlder(action.payload, _updatedNotifications),
        listPage: prevPageNumber,
        viewMoreLoader: false,
      };

    case UPDATE_HOME_NOTIFICATION_PAYLOAD:
      let homePrevPageNumber = state.homeListPage;
      homePrevPageNumber = homePrevPageNumber + 1;
      let _oldHomeNotification = [...state.homeNotifications];
      const _homeUpdatedNotifications = [..._oldHomeNotification, ...action.payload.notifications];
      return {
        ...state,
        homeNotifications: [..._homeUpdatedNotifications],
        homeFetchMore: viewMoreNotificationsHanlder(action.payload, _homeUpdatedNotifications),
        homeListPage: homePrevPageNumber,
        homeViewMoreLoader: false,
      };

    case UPDATE_VIEWMORE_LOADER:
      return { ...state, viewMoreLoader: action.payload };

    case UPDATE_HOME_VIEWMORE_LOADER:
      return { ...state, homeViewMoreLoader: action.payload };

    case SET_LT_SEARCH_DATA:
      return {
        ...state,
        ltSearchData: action.payload,
      };
    default:
      return { ...state };
  }
};

export default notificationsReducer;
