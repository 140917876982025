import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";
import Icon from "../../../lt-dashboard/components/Icon";
import Button from "../../../lt-dashboard/components/Button";
import useClickOutside from "../../../lt-dashboard/components/useClickOutside";

export default function LandingNav() {
  const router = useRouter();
  const el = useRef(null);
  const elProducts = useRef(null);

  const [open, setOpen] = useState(false);
  const [isActive, setActive] = useState(null);
  const [openProducts, setOpenProducts] = useState(false);
  const [innerProduct, setInnerProduct] = useState({
    platform: "",
    fuel: "",
    dispatcher: "",
    MTC: "",
  });
  const isTablet = useMediaQuery({ minWidth: "300px", maxWidth: "1026px" });

  const token = localStorage.getItem("token");
  const LTtoken = localStorage.getItem("LTtoken");
  const mainToken = localStorage.getItem("mainToken");
  const DispatcherToken = localStorage.getItem("DispatcherToken");

  useEffect(() => {
    let path = router.pathname.split("/");
    setActive(path[2]);
  }, []);

  useEffect(() => {
    if (router.pathname.includes("/Landing/Support")) {
      setOpen(false);
      setActive("Help&Support");
    } else if (router.pathname.includes("/Landing/Blogs")) {
      setOpen(false);
      setActive("Blog");
    } else if (router.pathname.includes("Credit-Card")) {
      setInnerProduct({
        fuel: "text-bgActiveButton",
        platform: "",
        dispatcher: "",
        MTC: "",
      });
    } else if (router.pathname.includes("Platform")) {
      setInnerProduct({
        fuel: "",
        platform: "text-bgActiveButton",
        dispatcher: "",
        MTC: "",
      });
    } else if (router.pathname.includes("Dispatcher")) {
      setInnerProduct({
        fuel: "",
        dispatcher: "text-bgActiveButton",
        platform: "",
        MTC: "",
      });
    } else if (router.pathname.includes("motor-truck-cargo")) {
      setInnerProduct({
        fuel: "",
        dispatcher: "",
        platform: "",
        MTC: "text-bgActiveButton",
      });
    }
  }, [router.pathname]);

  useClickOutside(el, () => {
    setOpen(false);
  });

  useClickOutside(elProducts, () => {
    setOpenProducts(false);
  });

  const loadNavBarButtons = () => {
    if (mainToken || LTtoken || DispatcherToken || token) {
      return (
        <div className="flex flex-row  xs:ml-20 cursor-pointer">
          <Button
            title="Dashboard"
            onButtonClick={() =>
              router.replace(
                mainToken || token
                  ? "/Dashboard"
                  : LTtoken
                  ? "/LTteam/Companies"
                  : "/Dispatcher/Home"
              )
            }
          />
        </div>
      );
    } else {
      return (
        <div className="flex flex-row space-x-3 xs:ml-16 items-center justify-center cursor-pointer">
          <a
            onClick={() => {
              setActive("Login");
              router.push("/Auth/Login");
            }}
            href={`/Auth/Login`}
            className={`font-hkBold xs:hidden custom:hidden
              ${isActive === "Login" && "active"} `}
          >
            Sign in
          </a>

          <Button
            title="SIGN UP"
            width={"w-2/3"}
            onButtonClick={() => {
              setActive("");
              localStorage.removeItem("withOutDot");
              router.push("/Auth/Signup");
            }}
          />
        </div>
      );
    }
  };

  function onProductClick(evt) {
    const clicked = evt.target.closest(".wah");
    const index = clicked.dataset.index;
    if (+index === 0) {
      if (openProducts === true) {
        setOpenProducts(false);
      } else {
        setOpenProducts(true);
        setActive("Products");
      }
    }
  }

  return (
    <nav className="flex items-center whitespace-nowrap justify-between h-20 fixed w-full z-50 xs:px-4 custom:px-4 px-8 bg-landing">
      <div
        onClick={() => {
          setActive("");
          router.push("/");
        }}
        className="flex items-center cursor-pointer"
      >
        <img
          width={"70px"}
          src="/Images/logo-circle.webp"
          className="rounded-full mt-1"
        />
        <img width={"180px"} src="/Images/wordmark-luckytruck.webp" />
      </div>

      {!isTablet ? (
        <>
          <div ref={elProducts}>
            <div
              data-index={"0"}
              onClick={onProductClick}
              className="flex flex-row items-center wah"
            >
              <p
                className={`font-hkBold px-5 cursor-pointer
                ${openProducts && isActive === "Products" && "active"} `}
              >
                Products
              </p>
              {openProducts ? (
                <Icon name={"icon-drop-open"} />
              ) : (
                <Icon name={"icon-drop-close"} />
              )}
            </div>
            {openProducts ? (
              <div className=" absolute inset-x-0 top-20 bg-white h-56 w-full text-landingText ">
                <div className=" grid grid-cols-4 lg:grid-cols-5 p-10 gap-x-4">
                  <div className=" col-span-1 lg:col-span-2">
                    <h3 className=" font-hkBold">Insurance Products</h3>
                    <div className=" grid grid-cols-2 font-hkRegular">
                      <div className=" flex flex-col gap-y-3 mt-2">
                        <a
                          onClick={() => {
                            setOpen(false);
                            setActive("AL");
                          }}
                          href={`/Landing/Products/Platform`}
                          className={isActive === "AL" ? "active" : ""}
                        >
                          <h4>Auto Liability</h4>
                        </a>
                        <a
                          onClick={() => {
                            setOpen(false);
                            setActive("PD");
                          }}
                          href={`/Landing/Products/Platform`}
                          className={isActive === "PD" ? "active" : ""}
                        >
                          <h4>Physical Damage</h4>
                        </a>
                        <a
                          onClick={() => {
                            setOpen(false);
                            setActive("MTC");
                          }}
                          href={`/Landing/Products/Platform`}
                          className={isActive === "MTC" ? "active" : ""}
                        >
                          <h4>Motor Truck Cargo</h4>
                        </a>
                      </div>
                      <div className=" flex flex-col gap-y-3 mt-2">
                        <a
                          onClick={() => {
                            setOpen(false);
                            setActive("NTL");
                          }}
                          href={`/Landing/Products/Platform`}
                          className={isActive === "NTL" ? "active" : ""}
                        >
                          <h4>Non-Trucking Liability</h4>
                        </a>
                        <a
                          onClick={() => {
                            setOpen(false);
                            setActive("GL");
                          }}
                          href={`/Landing/Products/Platform`}
                          className={isActive === "GL" ? "active" : ""}
                        >
                          <h4>General Liability</h4>
                        </a>

                        <a
                          onClick={() => {
                            setOpen(false);
                            setActive("Warranties");
                          }}
                          href={`/Landing/Products/Warranties`}
                          className={isActive === "Warranties" ? "active" : ""}
                        >
                          <h4>Warranties</h4>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <a
                      onClick={() => {
                        setOpen(false);
                        setActive("MTC");
                      }}
                      href={`/Landing/Products/motor-truck-cargo`}
                      className={isActive === "MTC" ? "active" : ""}
                    >
                      <h3 className=" font-hkBold">Instant MTC</h3>
                      <img
                        src="/Images/product1.png"
                        className=" mt-2"
                        width={300}
                      />
                    </a>
                  </div>
                  {/* <div className="">
                    <a
                      onClick={() => {
                        setOpen(false);
                        setActive("Credit-Card");
                      }}
                      href={`/Landing/Products/Credit-Card`}
                      className={isActive === "Credit-Card" ? "active" : ""}
                    >
                      <h3 className=" font-hkBold">Credit Card</h3>
                      <img
                        src="/Images/product2.png"
                        className=" mt-2"
                        width={300}
                      />
                    </a>
                  </div> */}
                  <div className="">
                    <a
                      onClick={() => {
                        setOpen(false);
                        setActive("Platform");
                      }}
                      href={`/Landing/Products/Platform`}
                      className={isActive === "Platform" ? "active" : ""}
                    >
                      <h3 className=" font-hkBold">How It Works</h3>
                      <img
                        src="/Images/product3.png"
                        className=" mt-2"
                        width={300}
                      />
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Link href={`/Landing/About`}>
            <a
              onClick={() => {
                setOpen(false);
                setActive("About");
              }}
              href={`/Landing/About`}
              className={`font-hkBold px-5
              ${isActive === "About" && "active"} `}
            >
              About
            </a>
          </Link>
          <Link href={`/Landing/Reviews`}>
            <a
              onClick={() => {
                setOpen(false);
                setActive("Reviews");
              }}
              href={`/Landing/Reviews`}
              className={`font-hkBold px-5
              ${isActive === "Reviews" && "active"} `}
            >
              Reviews
            </a>
          </Link>
          <Link href={`/Landing/Blogs`}>
            <a
              onClick={() => {
                setOpen(false);
                setActive("Blog");
              }}
              href={`/Landing/Blogs`}
              className={`font-hkBold px-5
              ${isActive === "Blog" && "active"} `}
            >
              Blog
            </a>
          </Link>
          <Link href={`/Landing/Support`}>
            <a
              onClick={() => {
                setOpen(false);
                setActive("Help&Support");
              }}
              href={`/Landing/Support`}
              className={`font-hkBold px-5
              ${isActive === "Help&Support" && "active"} `}
            >
              Help & Support
            </a>
          </Link>
          <div className="">{loadNavBarButtons()}</div>
        </>
      ) : (
        <>
          {loadNavBarButtons()}
          <div className="block xl:hidden">
            <button
              className="flex items-center px-3 py-2 rounded"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <>
                  <Icon name="icon-close" />
                </>
              ) : (
                <svg
                  className="fill-current h-5 w-5"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              )}
            </button>
          </div>
        </>
      )}
      {open && (
        <div
          ref={el}
          style={{ height: "40vh" }}
          className="flex flex-col gap-7 absolute top-20 bg-white rounded-md inset-x-2 overflow-scroll"
        >
          {mainToken || LTtoken || DispatcherToken ? null : (
            <div className="flex flex-row justify-between cursor-pointer text-bgActiveButton items-center p-6 pb-0 mx-0 custom:hidden sm:hidden">
              <h3 onClick={() => router.push("/Auth/Login")}>Sign In</h3>
              <h3 onClick={() => router.push("/Auth/Signup")}>Sign Up</h3>
            </div>
          )}

          <div
            data-index={"0"}
            onClick={onProductClick}
            className="flex flex-row items-center wah"
          >
            <a
              className={`font-hkBold px-5 cursor-pointer
                ${openProducts && isActive === "Products" && "active"} `}
            >
              Products
            </a>
            {openProducts ? (
              <Icon name={"icon-drop-open"} />
            ) : (
              <Icon name={"icon-drop-close"} />
            )}
          </div>
          {openProducts && (
            <div className="bg-white text-landingText px-10">
              <h3 className="font-hkBold">Insurance Products</h3>
              <div className="grid grid-cols-2 font-hkRegular">
                <div className="flex flex-col gap-y-3 mt-2">
                  <a
                    onClick={() => {
                      setOpen(false);
                      setActive("AL");
                    }}
                    href={`/Landing/Products/Platform`}
                    className={isActive === "AL" ? "active" : ""}
                  >
                    <h4>Auto Liability</h4>
                  </a>
                  <a
                    onClick={() => {
                      setOpen(false);
                      setActive("PD");
                    }}
                    href={`/Landing/Products/Platform`}
                    className={isActive === "PD" ? "active" : ""}
                  >
                    <h4>Physical Damage</h4>
                  </a>
                  <a
                    onClick={() => {
                      setOpen(false);
                      setActive("MTC");
                    }}
                    href={`/Landing/Products/Platform`}
                    className={isActive === "MTC" ? "active" : ""}
                  >
                    <h4>Motor Truck Cargo</h4>
                  </a>
                </div>
                <div className=" flex flex-col gap-y-3 mt-2">
                  <a
                    onClick={() => {
                      setOpen(false);
                      setActive("NTL");
                    }}
                    href={`/Landing/Products/Platform`}
                    className={isActive === "NTL" ? "active" : ""}
                  >
                    <h4>Non-Trucking Liability</h4>
                  </a>
                  <a
                    onClick={() => {
                      setOpen(false);
                      setActive("GL");
                    }}
                    href={`/Landing/Products/Platform`}
                    className={isActive === "GL" ? "active" : ""}
                  >
                    <h4>General Liability</h4>
                  </a>

                  <a
                    onClick={() => {
                      setOpen(false);
                      setActive("Warranties");
                    }}
                    href={`/Landing/Products/Warranties`}
                    className={isActive === "Warranties" ? "active" : ""}
                  >
                    <h4>Warranties</h4>
                  </a>
                </div>
              </div>

              <div className="py-5">
                <a
                  onClick={() => {
                    setOpen(false);
                    setActive("MTC");
                  }}
                  href={`/Landing/Products/motor-truck-cargo`}
                  className={isActive === "MTC" && "active"}
                >
                  <h3 className="font-hkBold">Instant MTC</h3>
                  <img
                    src="/Images/product1.png"
                    className=" mt-2"
                    width={300}
                  />
                </a>
              </div>

              {/* <div>
                <a
                  onClick={() => {
                    setOpen(false);
                    setActive("Credit-Card");
                  }}
                  href={`/Landing/Products/Credit-Card`}
                  className={isActive === "Credit-Card" && "active"}
                >
                  <h3 className="font-hkBold">Credit Card</h3>
                  <img src="/Images/product2.png" className=" mt-2" width={300} />
                </a>
              </div> */}
              <div className="py-5">
                <a
                  onClick={() => {
                    setOpen(false);
                    setActive("Platform");
                  }}
                  href={`/Landing/Products/Platform`}
                  className={isActive === "Platform" ? "active" : ""}
                >
                  <h3 className="font-hkBold">How It Works</h3>
                  <img
                    src="/Images/product3.png"
                    className=" mt-2"
                    width={300}
                  />
                </a>
              </div>
            </div>
          )}

          <Link href={`/Landing/About`}>
            <a
              onClick={() => {
                setOpen(false);
                setActive("About");
              }}
              href={`/Landing/About`}
              className={`font-hkBold px-5
              ${isActive === "About" && "active"} `}
            >
              About
            </a>
          </Link>
          <Link href={`/Landing/Reviews`}>
            <a
              onClick={() => {
                setOpen(false);
                setActive("Reviews");
              }}
              href={`/Landing/Reviews`}
              className={`font-hkBold px-5
              ${isActive === "Reviews" && "active"} `}
            >
              Reviews
            </a>
          </Link>
          <Link href={`/Landing/Blogs`}>
            <a
              onClick={() => {
                setOpen(false);
                setActive("Blog");
              }}
              href={`/Landing/Blogs`}
              className={`font-hkBold px-5
              ${isActive === "Blog" && "active"} `}
            >
              Blog
            </a>
          </Link>
          <Link href={`/Landing/Support`}>
            <a
              onClick={() => {
                setOpen(false);
                setActive("Help&Support");
              }}
              href={`/Landing/Support`}
              className={`font-hkBold px-5
              ${isActive === "Help&Support" && "active"} `}
            >
              Help & Support
            </a>
          </Link>
        </div>
      )}
    </nav>
  );
}
