import { gql } from "@apollo/client";

export const MVR_CHECKR_SUBSCRIPTION = gql`
subscription MVRCheckrSubscription($companyId: ID!){
    MVRCheckrSubscription(companyId:$companyId){
        id
        payload
        createdAt
        lastUpdated
        status
        severity
        type
        seenBy
    }
}

`
export const POLCIY_CREATED_SUBSCRIPTION = gql`
subscription PolicyCreatedSubscription($companyId: ID!){
    PolicyCreatedSubscription(companyId:$companyId){
        id
        payload
        createdAt
        lastUpdated
        status
        severity
        type
        seenBy
    }
}

`
export const QUOTE_UPDATE_SUBSCRIPTION = gql`
subscription NonInstantQuoteUpdateSubscription($companyId: ID!){
    NonInstantQuoteUpdateSubscription(companyId:$companyId){
        id
        payload
        createdAt
        lastUpdated
        status
        severity
        type
        seenBy
    }
}

`
export const QUOTE_QUOTED_SUBSCRIPTION = gql`
subscription QuoteQuotedUpdateSubscription($companyId: ID!){
    QuoteQuotedUpdateSubscription(companyId:$companyId){
        id
        payload
        createdAt
        lastUpdated
        status
        severity
        type
        seenBy
    }
}

`
export const ENDORSEMENT_RESOLVED_SUBSCRIPTION = gql`
subscription EndorsementResolvedSubscription($companyId: ID!){
    EndorsementResolvedSubscription(companyId:$companyId){
        id
        payload
        createdAt
        lastUpdated
        status
        severity
        type
        seenBy
    }
}

`