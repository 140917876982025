export const updateViewMore = (totalCompanies, resultCount) => {
  if (totalCompanies < resultCount) {
    return true;
  } else {
    return false;
  }
};
export const updateBackButtonCompanies = (totalCompanies, currentPage) => {
  let firstIndex = (currentPage - 2) * 10;
  let check = totalCompanies.slice(firstIndex, firstIndex + 10);
  return totalCompanies.slice(firstIndex, firstIndex + 10);
};

export const updateListPage = (stateListPage, result) => {
  if (result.length > 0) {
    return (stateListPage = stateListPage + 1);
  }
};

export const setDispatcherCompanySearchResult = (payload, dispatcherId) => {
  // const dispatcher = JSON.parse(localStorage.getItem("dispatcherData"))
  // if (dispatcher) {

  // }
  const transformedData = payload.map((c) => {
    const filteredCurrentDispatcher = c.dispatchers.filter(
      (d) => d.dispatcher.id === dispatcherId
    );
    if (filteredCurrentDispatcher.length > 0) {
      c["isDispatcher"] = true;
      c["dispatcherAccess"] = filteredCurrentDispatcher[0].accessAllowed;
      c["activeDispatcherId"] = dispatcherId;
    }
    return c;
  });
  return transformedData;
};

export const updateDispatchersPayload = (state, payload) => {
  const prevCompanies = [...state.companies];
  return [...prevCompanies, ...payload.dispatcherOfCompanies];
};
