import React from "react";
import Icon from "../Icon";

export default function Button({
  icon,
  title,
  width,
  height,
  disabled,
  color = "",
  onButtonClick,
  textColor = "",
  additionStyle = "",
  ...rest
}) {
  return (
    <div className={`relative ${width}`}>
      <button
        {...rest}
        disabled={disabled}
        onClick={!disabled && onButtonClick}
        className={`${width} ${height} ${additionStyle} ${color} ${textColor} gap-3
          ${
            disabled
              ? "InActiveButton cursor-default"
              : "activeButton cursor-pointer"
          }`}
      >
        <span>{title}</span>
        {icon && <Icon name={icon} additionStyle="top-3" />}
      </button>
    </div>
  );
}
