import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPANY_NOTES_MUTATION,
  GET_COMPANY_INFO,
} from "../../pages/api/mutations";
import { useMutation } from "@apollo/client";
import throttle from "lodash.throttle";
import {
  setCompanyDetails,
  updateNotesInCompany,
} from "../../Redux/Actions/companyActions";

export default function Notes({ setNotes, notesFlag }) {
  const { company } = useSelector((state) => state.company);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [updateCompanyNotesMutation] = useMutation(COMPANY_NOTES_MUTATION);
  const [companyInfo] = useMutation(GET_COMPANY_INFO, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchCompanyInfo();
    return () => {
      throttledEventHandler.cancel();
    };
  }, []);

  const fetchCompanyInfo = async () => {
    try {
      setLoading(true);
      const companyData = await companyInfo();
      dispatch(setCompanyDetails(companyData.data.fetchCompanyInfo));
      setLoading(false);
    } catch (error) {
      console.log("error on fetching company info", error);
    }
  };

  const throttledEventHandler = useMemo(
    () => throttle((nextValue) => updateNotesHandler(nextValue), 5000),
    []
  );

  const updateNotesHandler = async (value) => {
    try {
      if (value) {
        await updateCompanyNotesMutation({
          variables: { notes: value },
        });
        return;
      } else {
        return;
      }
    } catch (error) {
      console.log("error on updating notes", error);
    }
  };

  const onInputChange = (event) => {
    const { value: nextValue } = event.target;
    throttledEventHandler(nextValue);
    dispatch(updateNotesInCompany(nextValue));
  };

  const saveAndClose = async () => {
    updateNotesHandler(company?.notes);
    setNotes(!notesFlag);
  };

  return (
    <div className="notesCard">
      <div className="header flex ">
        <div>
          <p className="heading">NOTES</p>
        </div>
        <div
          className="flex action"
          onClick={() => {
            saveAndClose();
          }}
        >
          SAVE AND CLOSE <p> X</p>
        </div>
      </div>
      <div className="body">
        <textarea
          name="notes"
          rows="6"
          onChange={onInputChange}
          placeholder="Type Something..."
          className="ring-transparent"
          value={
            loading ? "Syncing notes..." : company?.notes ? company?.notes : ""
          }
        />
      </div>
    </div>
  );
}
