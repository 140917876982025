import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"

import { WebSocketLink } from "@apollo/client/link/ws"
import { setContext } from "@apollo/client/link/context"
import { SubscriptionClient } from "subscriptions-transport-ws"
import ws from "ws"
import TimeoutLink from "apollo-link-timeout"

const timeout = 90000 // Set the desired timeout value in milliseconds

// Create a TimeoutLink instance
const timeoutLink = new TimeoutLink(timeout)

const fetchWithTimeout = async (url, options) => {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(url, { ...options, signal: controller.signal })
  clearTimeout(timeoutId)
  return response
}

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_BACKEND_URL_DEV,
  fetch: fetchWithTimeout,
})

const createWSLink = () => {
  const webSocketSubscription = new SubscriptionClient(
    process.env.NEXT_PUBLIC_BACKEND_WS_URL_DEV,
    {
      lazy: true,
      reconnect: true,
    },
    typeof window === "undefined" ? ws : null
  )
  webSocketSubscription.maxConnectTimeGenerator.duration = () =>
    webSocketSubscription.maxConnectTimeGenerator.max
  return new WebSocketLink(webSocketSubscription)
}
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : ""
  const mainToken = localStorage.getItem("mainToken")
    ? localStorage.getItem("mainToken")
    : ""
  const ltTok = localStorage.getItem("LTtok")
    ? localStorage.getItem("LTtok")
    : ""
  const LTtoken = localStorage.getItem("LTtoken")
    ? localStorage.getItem("LTtoken")
    : ""

  const dispathcerToken = localStorage.getItem("DispatcherToken")
    ? localStorage.getItem("DispatcherToken")
    : ""
  // return the headers to the context so httpLink can read them

  // var auth = "Bearer " + token !== "" ? token : LTtoken;
  let auth = ""
  if (token && token !== "") {
    auth = "Bearer " + token
  } else if (mainToken && mainToken !== "") {
    auth = "Bearer " + mainToken
  } else if (LTtoken && LTtoken !== "") {
    auth = "Bearer " + LTtoken
  } else if (dispathcerToken && dispathcerToken !== "") {
    auth = "Bearer " + dispathcerToken
  } else if (ltTok && ltTok !== "") {
    auth = "Bearer " + ltTok
  }

  return {
    headers: {
      ...headers,
      authorization: auth,
    },
  }
})
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  createWSLink(),
  authLink.concat(timeoutLink).concat(httpLink) // Add the timeoutLink here
)

export default function createApolloClient() {
  const ssrMode = typeof window === "undefined"
  let link
  if (ssrMode) {
    link = authLink.concat(httpLink) // executed on server
  } else {
    link = splitLink // executed on client
  }
  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      mutate: {
        fetchPolicy: "no-cache",
      },
    },
  })
}
