import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import { useRouter } from "next/router";
import { useSubscription } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { setMenu } from "../../Redux/Actions/sidebarAction";
import { triggerDelightedSurveyForCurrentUser } from "../../utils/delighted";
import { ENDORSEMENT_RESOLVED_SUBSCRIPTION } from "../../pages/api/subscriptions";
import { setDashboardTabIndex, setDocumentTabIndex, setSettingTabIndex } from "../../Redux/Actions/tabAction";

export default function SideBar({ callback, isCustomCheck, setIsCustom }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const sideMenu = useSelector((state) => state.sideBarMenu.menu);
  const { showPolicesOrQuotes } = useSelector((state) => state.quoteReducer);

  const [open, setOpen] = useState(true);
  const [delightedObj, setDelightedObj] = useState(null);

  const successToast = (message) => dispatch(successToast(message));
  const errorToast = (message) => dispatch(errorToast(message));

  useEffect(() => {
    if (isCustomCheck) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isCustomCheck]);

  let companyId = user?.company?.id;
  const {
    data: eData,
    loading: eLoading,
    error: eError,
  } = useSubscription(ENDORSEMENT_RESOLVED_SUBSCRIPTION, {
    variables: {
      companyId,
    },
  });

  useEffect(() => {
    // integrating delighted with this component
    if (typeof window !== "undefined") {
      const script = document.createElement("script");
      script.src = !(function (e, t, r, n) {
        if (!e[n]) {
          for (
            var a = (e[n] = []),
              i = [
                "survey",
                "reset",
                "config",
                "init",
                "set",
                "get",
                "event",
                "identify",
                "track",
                "page",
                "screen",
                "group",
                "alias",
              ],
              s = 0;
            s < i.length;
            s++
          ) {
            var c = i[s];
            a[c] =
              a[c] ||
              (function (e) {
                return function () {
                  var t = Array.prototype.slice.call(arguments);
                  a.push([e, t]);
                };
              })(c);
          }
          a.SNIPPET_VERSION = "1.0.1";
          var o = t.createElement("script");
          (o.type = "text/javascript"),
            (o.async = !0),
            (o.src = "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" + r + "/" + n + ".js");
          var l = t.getElementsByTagName("script")[0];
          l.parentNode.insertBefore(o, l);
        }
      })(window, document, "OWbempullS2o61L3", "delighted");
      script.async = true;
      document.body.appendChild(script);
      setDelightedObj(delighted);
    }
  }, []);

  // endorsement resolved notification for Delighted survey
  useEffect(() => {
    const onCompleted = async (data) => {
      if (data && data.EndorsementResolvedSubscription) {
        const ltUser = localStorage.getItem("ltUserData");
        if (!ltUser) {
          const user = JSON.parse(localStorage.getItem("userData"));
          triggerDelightedSurveyForCurrentUser(delightedObj, user);
        }
      }
    };

    const onError = (mvrError) => {
      /* magic */
    };
    if (onCompleted || onError) {
      if (onCompleted && !eLoading) {
        onCompleted(eData);
      } else if (onError && !eLoading && eError) {
        onError(eError);
      }
    }
  }, [eLoading, eData, eError]);

  const handleSubMenuClick = (mainItem) => {
    if (!open && mainItem.title === "Assets") {
      setOpen(true);
      callback(!open);
    }

    let copySidebar = [...sideMenu];

    let updatedSidebar = copySidebar.map((item) => {
      if (item.id === mainItem.id && item.title === "Assets") {
        return {
          ...item,
          isOpen: !item.isOpen,
          subMenu: [
            {
              subTitle: "Vehicles",
              isActive: true,
            },
            {
              subTitle: "Drivers",
              isActive: false,
            },
            {
              subTitle: "Commodities",
              isActive: false,
            },
          ],
        };
      } else if (item.id === mainItem.id) {
        return { ...item, isOpen: !item.isOpen };
      } else {
        return { ...item, isOpen: false };
      }
    });

    dispatch(setMenu(updatedSidebar));

    if (mainItem.title === "Dashboard" || mainItem.title === "Assets") {
      if (mainItem.title === "Assets") {
        dispatch(setDashboardTabIndex(2));
      } else {
        dispatch(setDashboardTabIndex(0));
      }
      router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD);
    } else if (mainItem.title === "Policies") {
      router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD);
      dispatch(setDashboardTabIndex(5));
    } else if (mainItem.title === "Documents") {
      router.push(process.env.NEXT_PUBLIC_USER_DOCUMENTS);
      dispatch(setDocumentTabIndex(1));
    } else if (mainItem.title === "Settings") {
      router.push(process.env.NEXT_PUBLIC_SETTINGS);
      dispatch(setSettingTabIndex(1));
    } else if (mainItem.title === "Dispatchers") {
      router.push(process.env.NEXT_PUBLIC_SETTINGS);
      dispatch(setSettingTabIndex(4));
    } else if (mainItem.title === "Quotes") {
      router.push(process.env.NEXT_PUBLIC_USER_DOCUMENTS);
      dispatch(setDocumentTabIndex(2));
    }
  };

  const onClickSubMenu = (parentIndex, subMenuIndex) => {
    const updatedMenu = [...sideMenu];
    const updatedSubMenu = updatedMenu[parentIndex].subMenu.map((item, index) => ({
      ...item,
      isActive: index === subMenuIndex,
    }));
    updatedMenu[parentIndex] = {
      ...updatedMenu[parentIndex],
      subMenu: updatedSubMenu,
    };

    if (updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle === "Vehicles") {
      dispatch(setDashboardTabIndex(2));
    } else if (updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle === "Drivers") {
      dispatch(setDashboardTabIndex(3));
    } else if (updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle === "Commodities") {
      dispatch(setDashboardTabIndex(4));
    }

    dispatch(setMenu(updatedMenu));
  };

  return (
    <div
      className={` flex flex-col  h-full bg-gray4 rounded-r-lg fixed z-30 flex-wrap  ${
        open ? " w-64" : "w-20"
      } transition-all duration-300 xs:hidden `}
    >
      <img
        src={`${!open ? "/Images/Tab-open.webp" : "/Images/Tab-close.webp"}`}
        onClick={() => {
          setOpen(!open);
          callback(!open);
          // setMenuItems(Menu);
          setIsCustom(false);
        }}
        alt="truck"
        className={` absolute cursor-pointer -right-10 top-3 w-12 h-12`}
      />

      <div className="mt-20">
        <ul className="flex flex-col  " style={{ height: "85vh" }}>
          {sideMenu.map((item, i) => {
            if (
              (!showPolicesOrQuotes && item.title === "Policies") ||
              (!showPolicesOrQuotes && item.title === "Quotes")
            ) {
              return;
            }
            return (
              <>
                <li
                  key={i}
                  className={` ${i === sideMenu.length - 1 ? " mt-auto" : ""}  text-white cursor-pointer mt-5 ${
                    item.isOpen && open && "onhover"
                  }  relative  `}
                  onClick={() => handleSubMenuClick(item)}
                >
                  {item.isOpen ? (
                    <div
                      className={` bg-blue5 w-2 h-8 rounded-l-none rounded-r-md absolute  ${
                        open ? "-left-5" : "left-0"
                      } -top-1  `}
                    />
                  ) : null}
                  <div className={`flex items-center space-x-4 ${!open ? "ml-6" : "ml-10"}`}>
                    <Icon name={item.icon} />
                    <h3 className={`${!open && "hidden"} ${item.isOpen ? "title-sidebar-show" : "title-sidebar"} `}>
                      {" "}
                      {item.title}
                    </h3>
                    {item.subMenu && open ? <Icon name={item.isOpen ? "icon-close-menu" : "icon-open-menu"} /> : null}
                  </div>
                </li>

                {item.subMenu && open ? (
                  <ul className={`subMenu ${item.isOpen && "showMenu"} flex flex-col `}>
                    {item.subMenu?.map((subMenu, subIndex) => {
                      return (
                        <li
                          key={subIndex}
                          onClick={() => onClickSubMenu(i, subIndex)}
                          className={`relative  text-white  mt-3 cursor-pointer    ml-24`}
                        >
                          <div className={`flex items-center `}>
                            <h3 className={`${subMenu.isActive && " subMenuActive  "}`}>{subMenu.subTitle}</h3>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
