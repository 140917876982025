export const updateUserHelper = (users, toBeUpdate) => {
  const index = users.findIndex((v) => v.id === toBeUpdate.id);
  if (index > -1) {
    users[index] = toBeUpdate;
  }
  return [...users];
};

export const removeUserHelper = (users, toBeRemoved) => {
  return users.filter((v) => v.id !== toBeRemoved.id);
};

export const lossRunsFlagHelper = (lossRuns) => {
  if (typeof window !== "undefined") {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData["company"]) {
      const userCompany = userData.company;
      if (userCompany.yearsInBusiness) {
        if (Number(userCompany.yearsInBusiness) > 0 && lossRuns.length > 0) {
          return false;
        } else if (
          Number(userCompany.yearsInBusiness) > 0 &&
          lossRuns.length == 0
        ) {
          return true;
        } else if (
          Number(userCompany.yearsInBusiness) == 0 &&
          lossRuns.length == 0
        ) {
          return false;
        } else if (
          Number(userCompany.yearsInBusiness) == 0 &&
          lossRuns.length > 0
        ) {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const updateCompanyDispatchers = (state, payload) => {
  const _dispatchers = [...state.dispatchers];
  const index = _dispatchers.findIndex((d) => d.dispatcher.id == payload.id);
  if (index > -1 && payload.accessAllowed !== "Rejected") {
    (_dispatchers[index]["role"] = payload.role),
      (_dispatchers[index]["accessAllowed"] = payload.accessAllowed);
    return _dispatchers;
  } else if (index > -1 && payload.accessAllowed === "Rejected") {
    return _dispatchers.filter((d) => d.dispatcher.id !== payload.id);
  }
};

export const setCompanyDispatchers = (payload) => {
  const dispatchers = [...payload];
  return dispatchers.map((d) => {
    return {
      accessAllowed: d.accessAllowed,
      dispatcher: d.dispatcher,
      role: d.role,
    };
  });
};

export const updateCompanyNotes = (value, state) => {
  const companyInfo = state.company;
  let _notes = companyInfo.notes;

  _notes = value;
  companyInfo.notes = _notes;
  return companyInfo;
};
