import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";
import ExportedImage from "next-image-export-optimizer";
import Icon from "../../../lt-dashboard/components/Icon";
import Button from "../../../lt-dashboard/components/Button";

export default function LandingFooter() {
  const router = useRouter();
  const [isMob, setIsMobile] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: "480px" });

  useEffect(() => {
    if (typeof window !== "undefined") setIsMobile(isMobile);
  }, [isMobile]);

  const loadRoutes = () => {
    const LTtoken = localStorage.getItem("LTtoken");
    const DispatcherToken = localStorage.getItem("DispatcherToken");
    const mainToken = localStorage.getItem("mainToken");

    if (mainToken || LTtoken || DispatcherToken) {
      return router.replace(
        mainToken
          ? "/Dashboard"
          : LTtoken
          ? "/LTteam/LTHome"
          : "/Dispatcher/Home"
      );
    } else {
      return router.replace(`/Auth/Signup`);
    }
  };

  return (
    <footer className="bg-white h-fit relative">
      <div
        className="absolute left-1/2 -top-7 xs:-top-4"
        style={{ transform: "translate(-50%,-50%)" }}
      >
        <ExportedImage
          src="/Images/logo-circle.webp"
          alt="Large Image"
          width={"80"}
          height={"80"}
        />
      </div>

      {router.pathname !== "/Landing/CreditCard" && (
        <div className="flex flex-col space-y-5 justify-center items-center p-10 custom:text-xl xs:text-lg text-4xl text-landingText font-hkBold">
          <h3>
            Ready to grow your business? <br /> Get Started with LuckyTruck
          </h3>
          <Button title="Join Today" onButtonClick={() => loadRoutes()} />
        </div>
      )}

      <div className="flex items-center p-16 pb-0">
        <img
          width={"60px"}
          src="/Images/logo-circle.webp"
          className="rounded-full mt-1"
        />
        <img width={"150px"} src="/Images/wordmark-luckytruck.webp" />
      </div>

      <div className="grid grid-cols-5 custom:grid-cols-3 custom:gap-y-5 xs:grid-cols-2 xs:gap-y-8 px-20 py-5">
        <div className="flex flex-col gap-5 text-gray4">
          <p className="font-hkBold">Products</p>
          <Link href={`/Landing/Products/Platform`}>Platform</Link>
          <Link href={`/Landing/Products/Credit-Card`}>Fuel Card</Link>
          <Link href={`/Landing/Products/motor-truck-cargo`}>MTC Coverage</Link>
          <Link href={`/Landing/Products/Dispatcher`}>Dispatchers</Link>
        </div>

        <div className="flex flex-col gap-5 text-gray4">
          <p className="font-hkBold">About Us</p>
          <Link href={`/Landing/About`}>Our Story</Link>
          {/* <Link href={`/Landing/CreditCard`}>Inverstors</Link> */}
          {/* <Link href={`/Landing/CreditCard`}>Press</Link> */}
          {/* <Link href={`/Landing/CreditCard`}>Careers</Link> */}
          <Link href={`/Landing/Blogs`}>Blog</Link>
        </div>

        <div className="flex flex-col gap-5 text-gray4">
          <p className="font-hkBold">Carriers</p>
          {/* <Link href={`/Landing/About`}>Partner With Us</Link> */}
          <Link href={`/Landing/Support`}>Frequently Asked Questions</Link>
        </div>

        <div className="flex flex-col gap-5 text-gray4">
          <p className="font-hkBold">Dispatchers</p>
          {/* <Link href={`/Landing/Support`}>Partner With Us</Link> */}
          <Link href={`/Landing/Products/Dispatcher`}>
            Dispatcher Experience
          </Link>
        </div>

        <div className="flex flex-col gap-5 text-gray4">
          <p className="font-hkBold">Connect</p>
          <p>Mon-Friday 8am-6pm ET</p>
          <a href="tel:513-506-2400">
            <p>1 (513) 647-6132</p>
          </a>
          <a href="mailto: support@luckytruck.com">
            <p>support@luckytruck.com</p>
          </a>

          <p className="font-hkBold text-lg pt-10">Follow Us</p>
          <div className="flex flex-row space-x-4">
            <a
              href={`https://www.linkedin.com/company/luckytruck/mycompany/`}
              className="cursor-pointer"
            >
              <img src="/Images/linkedin.png" width={"20"} height={"18"} />
            </a>
            <a
              href={`https://www.facebook.com/LuckyTruckPlatform`}
              className="cursor-pointer"
            >
              <Icon name="icon-fa" width={"30"} height={"22"} />
            </a>
          </div>
        </div>
      </div>

      <div className="border-t-custom border-borderColor mt-4 mx-12" />
      <div className="flex xs:flex-col flex-row items-center xs:gap-4 mt-4 mb-8 xs:text-base text-sm text-footerLinkText px-12">
        <h3 className="xs:order-4">© Copyright 2023, All Rights Reserved</h3>
        <div className="sm:ml-auto xs:order-2 cursor-pointer">
          <Link href={`/Landing/Privacy`}>
            <a href={`/Landing/Privacy`}>Privacy Policy</a>
          </Link>
        </div>
        <Link href={`/Landing/Terms`}>
          <a
            href={`/Landing/Terms`}
            className="sm:ml-10 xs:order-3 cursor-pointer"
          >
            Terms & Conditions
          </a>
        </Link>
        <Link href={`/sitemap.xml`}>
          <a
            href={`/sitemap.xml`}
            className="sm:ml-10 xs:order-1 cursor-pointer"
          >
            Sitemap
          </a>
        </Link>
      </div>
    </footer>
  );
}
