import { CLEAR_DATA, SET_DASHBOARD_TAB_INDEX, SET_DOCUMENTS_TAB_INDEX, SET_SETTINGS_TAB_INDEX } from "../Types";

const INITIAL_STATE = {
  tabDashboardIndex: 0,
  tabDocumentIndex: 1,
  tabSettingIndex: 1,
};

const TabReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DASHBOARD_TAB_INDEX:
      return { ...state, tabDashboardIndex: action.payload };

    case SET_DOCUMENTS_TAB_INDEX:
      return { ...state, tabDocumentIndex: action.payload };

    case SET_SETTINGS_TAB_INDEX:
      return { ...state, tabSettingIndex: action.payload };
    case CLEAR_DATA:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default TabReducer;
