export const setCommoditiesHelper = (state, payload) => {
  const _commodities = [...payload];

  const _notPendingCommodities = _commodities.filter(
    (v) => v.status == "clear" || v.status === null
  );
  const activeCommodities = _notPendingCommodities.filter((v) => !v.isDeleted);
  const pendingCommodities = _commodities.filter(
    (v) =>
      v.status == "pending" ||
      v.status == "pending-add" ||
      v.status == "pending-remove" ||
      v.status == "pending-update" ||
      v.status == "pending-restore"
  );
  const pastCommodities = _notPendingCommodities.filter((v) => v.isDeleted);
  return {
    ...state,
    commodities: [...activeCommodities],
    pastCommodities: [...pastCommodities],
    pendingCommodities: [...pendingCommodities],
  };
};

export const addCommodityHelper = (state, payload) => {
  const _commodities = [...state.commodities];
  const _pendingCommodities = [...state.pendingCommodities];

  if (payload.endorsment.length > 0) {
    _pendingCommodities.push(payload);
    return { ...state, pendingCommodities: [..._pendingCommodities] };
  } else {
    _commodities.push(payload);
    return { ...state, commodities: [..._commodities] };
  }
};

export const updateCommodityHelper = (state, payload) => {
  const _commodities = [...state.commodities];
  const _pendingCommodities = [...state.pendingCommodities];

  if (payload.endorsment.length > 0) {
    _pendingCommodities.push(payload);
    return {
      ...state,
      pendingCommodities: [..._pendingCommodities],
      commodities: removeCommodityHandler(_commodities, payload),
    };
  } else {
    const index = _commodities.findIndex((v) => v.id === payload.id);
    if (index > -1) {
      _commodities[index] = payload;
    }
    return { ...state, commodities: [..._commodities] };
  }
};

export const removeCommodityHelper = (state, payload) => {
  const _commodities = [...state.commodities];
  const _pendingCommodities = [...state.pendingCommodities];
  if (payload.endorsment.length > 0) {
    _pendingCommodities.push({ ...payload, status: "pending-remove" });
    return {
      ...state,
      pendingCommodities: [..._pendingCommodities],
      commodities: removeCommodityHandler(_commodities, payload),
    };
  } else {
    return {
      ...state,
      commodities: removeCommodityHandler(_commodities, payload),
      pastCommodities: updatePastCommodities(state, payload),
    };
  }
};

export const restoreCommodityHelper = (state, payload) => {
  let _activeCommodities = [...state.commodities];
  let _pastCommodities = [...state.pastCommodities];
  const _pendingCommodities = [...state.pendingCommodities];
  _pastCommodities = _pastCommodities.filter((v) => v.id !== payload.id);
  if (payload.endorsment.length > 0) {
    _pendingCommodities.push(payload);
    return {
      ...state,
      pendingCommodities: [..._pendingCommodities],
      pastCommodities: [..._pastCommodities],
    };
  } else {
    _activeCommodities.push(payload);
    return {
      ...state,
      commodities: [..._activeVehicles],
      pastCommodities: [..._pastCommodities],
    };
  }
};

const removeCommodityHandler = (commodities, toBeRemoved) => {
  return commodities.filter((v) => v.id !== toBeRemoved.id);
};

const updatePastCommodities = (state, toBeRemoved) => {
  const _pastCommodity = state.pastCommodities;
  _pastCommodity.push(toBeRemoved);
  return _pastCommodity;
};

export const calculateSum = (commodities) => {
  return commodities?.reduce((acc, commodity) => {
    return commodity.status === "" ||
      commodity.status === "clear" ||
      commodity.status === "pending-add" ||
      commodity.status === "pending-update"
      ? acc + commodity.percentage
      : acc;
  }, 0);
};
