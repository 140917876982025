import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_COMPANY_NOTIFICATIONS_MUTATION, UPDATE_NOTIFICATION_STATUS_MUTATION } from "../../pages/api/mutations";
import { setDashboardTabIndex, setDocumentTabIndex } from "../../Redux/Actions/tabAction";
import { updateNotification, updateNotificationWithBulkPayload } from "../../Redux/Actions/notificationActions";
import { selectFetchMore, selectListPage, selectViewMoreLoader } from "../../Redux/Selectors/notificationSelectors";
import { calculateTime } from "../../Redux/Utils/notificationsUtils";
import Icon from "../Icon";
import Button from "../Button";

export default function NotificationComp({ notifications, notificationTrayRef }) {
  const router = useRouter();
  const viewMore = useSelector(selectFetchMore);
  const pageNumber = useSelector(selectListPage);
  const viewMoreLoading = useSelector(selectViewMoreLoader);
  const dispatch = useDispatch();
  const [updateNotificationStatus] = useMutation(UPDATE_NOTIFICATION_STATUS_MUTATION);
  const [refetchNotificaionList] = useMutation(GET_COMPANY_NOTIFICATIONS_MUTATION);

  const notificationClickHandler = (n) => {
    if (!n.seen) {
      updateNotificationStatus({ variables: { id: n.id } }).then((res) => {
        if (res) {
          dispatch(updateNotification(res.data.updateNotificationStatus));
          if (n.type === "MVR_RESPONSE_FROM_CHECKR") {
            if (router.pathname != process.env.NEXT_PUBLIC_USER_DASHBOARD) {
              router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD);
            }
            dispatch(setDashboardTabIndex(2));
          } else if (n.type === "POLICY_CREATED_TYPE") {
            if (router.pathname != process.env.NEXT_PUBLIC_USER_DASHBOARD) {
              router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD);
            }
            dispatch(setDashboardTabIndex(5));
          }
        }
      });
    } else {
      if (n.type === "MVR_RESPONSE_FROM_CHECKR") {
        if (router.pathname != process.env.NEXT_PUBLIC_USER_DASHBOARD) {
          router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD);
        }
        dispatch(setDashboardTabIndex(2));
      } else if (n.type === "POLICY_CREATED_TYPE" || n.type === "RESOLVED_ENDORSEMENT_SUBSCRIPTION") {
        if (router.pathname != process.env.NEXT_PUBLIC_USER_DASHBOARD) {
          router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD);
        }
        dispatch(setDashboardTabIndex(5));
      } else if (n.type === "NON_INSTANT_QUTOE_UPDATE" || n.type === "QUOTE_QUOTE_UPDATE_SUBSCRIPTION") {
        if (router.pathname != process.env.NEXT_PUBLIC_USER_DOCUMENTS) {
          router.push(process.env.NEXT_PUBLIC_USER_DOCUMENTS);
        }
        dispatch(setDocumentTabIndex(2));
      }
    }
  };

  const loadMoreNotificationsHandler = () => {
    refetchNotificaionList({
      variables: {
        page: pageNumber,
      },
    }).then((res) => {
      dispatch(updateNotificationWithBulkPayload(res.data.notificationsList));
    });
  };

  const renderNotificationItem = (notification) => {
    if (notification.type === "MVR_RESPONSE_FROM_CHECKR") {
      return driverMVRNotification(notification);
    } else if (notification.type === "POLICY_CREATED_TYPE") {
      return policyCreationNotification(notification);
    } else if (notification.type === "NON_INSTANT_QUTOE_UPDATE") {
      return quoteNotification(notification);
    } else if (notification.type === "QUOTE_QUOTE_UPDATE_SUBSCRIPTION") {
      return quoteQuotedNotification(notification);
    } else if (notification.type === "RESOLVED_ENDORSEMENT_SUBSCRIPTION") {
      return endorsementResolvedNotification(notification);
    }
  };

  const driverMVRNotification = (n) => {
    return `${n.payload.driverfName ? n.payload.driverfName : ""} ${
      n.payload.driverlName ? n.payload.driverlName : "Driver"
    } MVR Report is ready with status ${n.payload.mvrStatus} !`;
  };

  const policyCreationNotification = (n) => {
    return !n.payload.policyNumber
      ? `Your policy from  ${n.payload.issuingCarrier} is almost ready!`
      : `Congrats your policy from  ${n?.payload?.issuingCarrier} is ready!`;
  };

  const endorsementResolvedNotification = (n) => {
    const issuingCarrier = n.payload.policy.issuingCarrier
      ? n.payload.policy.issuingCarrier
      : n.payload.policy[0].issuingCarrier;
    return `Your Endorsement ${n.payload.name} is resolved! ${
      n.payload.policy ? `for "${issuingCarrier}"" policy` : ""
    }`;
  };

  const quoteNotification = (n) => {
    return `Your quote from  ${n.payload.issuingCarrier} is ready!`;
  };

  const quoteQuotedNotification = (n) => {
    return `Your quote from  ${n.payload.quoteCarrier} is ready!`;
  };

  return (
    <div
      ref={notificationTrayRef}
      className={` relative xs:w-64 w-80 mt-5 z-50  bg-white border-custom border-borderNoti box-border rounded-md arrow-top overflow-y-scroll max-h-72  h-fit py-10`}
    >
      {notifications && notifications.length > 0 ? (
        notifications?.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className=" flex flex-row  gap-x-2 px-3 py-3"
                onClick={() => {
                  notificationClickHandler(item);
                }}
              >
                <Icon name="icon-quote-active" />
                <div className=" flex flex-col  ">
                  <p className=" mb-0 font-hkRegular text-inputColor text-label ">{renderNotificationItem(item)}</p>
                  <p className=" text-inputColor  opacity-30 font-hkRegular text-label mb-0">
                    {calculateTime(item.createdAt)}
                  </p>
                </div>
                <div className=" ml-auto mt-2">
                  <h6
                    className={`text-xs font-hkRegular ${
                      item.seen === "New" ? " text-newStatus" : "text-gray4 opacity-30"
                    }`}
                  ></h6>
                </div>
                {/* <div className=" ml-0 mt-1">
                <Icon name="icon-close" />
              </div> */}
              </div>
            </>
          );
        })
      ) : (
        <h3 className=" text-center font-hkMedium text-base">No new notifications</h3>
      )}

      {viewMore && !viewMoreLoading ? (
        <h3 className=" text-center activeButton mx-10" onClick={loadMoreNotificationsHandler}>
          view more
        </h3>
      ) : (
        ""
      )}
    </div>
  );
}
