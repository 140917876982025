export const updateVehicleHelper = (vehicles, toBeUpdate) => {
  const index = vehicles.findIndex((v) => v.id === toBeUpdate.id);
  if (index > -1) {
    vehicles[index] = toBeUpdate;
  }
  return [...vehicles];
};

export const setVehiclesHelper = (state, payload) => {
  const _vehicles = [...payload];
  const _notPendingVehicles = _vehicles.filter((v) => v.status == "clear");
  const activeVehicles = _notPendingVehicles.filter((v) => !v.isDeleted);
  const pendingVehicles = _vehicles.filter(
    (v) =>
      v.status == "pending" ||
      v.status == "pending-add" ||
      v.status == "pending-remove" ||
      v.status == "pending-restore"
  );
  const pastVehicles = _notPendingVehicles.filter((v) => v.isDeleted);
  return {
    ...state,
    vehicles: [...activeVehicles],
    pendingVehicles: [...pendingVehicles],
    pastVehicles: [...pastVehicles],
  };
};

export const addVehicleHelper = (state, payload) => {
  const _vehicles = [...state.vehicles];
  const _pendingVehicles = [...state.pendingVehicles];
  if (payload.endorsment.length > 0) {
    _pendingVehicles.push(payload);
    return { ...state, pendingVehicles: [..._pendingVehicles] };
  } else {
    _vehicles.push(payload);
    return { ...state, vehicles: [..._vehicles] };
  }
};

export const removeVehicleHelper = (state, payload) => {
  const _vehicles = [...state.vehicles];
  const _pendingVehicles = [...state.pendingVehicles];
  if (payload.endorsment.length > 0) {
    _pendingVehicles.push(payload);
    return {
      ...state,
      pendingVehicles: [..._pendingVehicles],
      vehicles: removeVehicleHandler(_vehicles, payload),
    };
  } else {
    return {
      ...state,
      vehicles: removeVehicleHandler(_vehicles, payload),
      pastVehicles: updatePastVehicles(state, payload),
    };
  }
};

export const restoreVehicleHelper = (state, payload) => {
  let _activeVehicles = [...state.vehicles];
  let _pastVehicles = [...state.pastVehicles];
  const _pendingVehicles = [...state.pendingVehicles];
  _pastVehicles = _pastVehicles.filter((v) => v.id !== payload.id);
  if (payload.endorsment.length > 0) {
    _pendingVehicles.push(payload);
    return {
      ...state,
      pendingVehicles: [..._pendingVehicles],
      pastVehicles: [..._pastVehicles],
    };
  } else {
    _activeVehicles.push(payload);
    return {
      ...state,
      vehicles: [..._activeVehicles],
      pastVehicles: [..._pastVehicles],
    };
  }
};

const removeVehicleHandler = (vehicles, toBeRemoved) => {
  return vehicles.filter((v) => v.id !== toBeRemoved.id);
};

const updatePastVehicles = (state, toBeRemoved) => {
  const _pastVehicle = state.pastVehicles;
  _pastVehicle.push(toBeRemoved);
  return _pastVehicle;
};
