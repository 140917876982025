export const triggerDelightedSurveyForCurrentUser = (delighted, user) => {


    delighted.survey({
        email: user.email, // customer email (optional)
        name: user.name,
        properties: {
            plan: "Medium",
            company: "Luckytruck"
        }
    });
}