import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import LandingNav from "../Landing/LandingNavbar";
import LandingFooter from "../Landing/LandingFooter";

const LandingLayout = ({ children }) => {
  const router = useRouter();
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    if (router.pathname.includes("Landing/Products/Credit-Card") || router.pathname.includes("Landing/CreditCard")) {
      setShowBanner(false);
    }
  }, [router.pathname]);

  return (
    <div className="flex flex-col relative">
      <LandingNav />
      {/* {showBanner && <Banner setShowBanner={setShowBanner} />} */}

      {/* main section */}

      <div className="landingMain duration-300 mt-14">
        {/* <MobileBrokerTabView /> */}
        {children}
      </div>
      <LandingFooter />
    </div>
  );
};
export default LandingLayout;
