import AuthReducer from "./auth";
import TabReducer from "./tabReducer";
import { combineReducers } from "redux";
import policyReducer from "./policyReducer";
import QuoteReducer from "./quotesReducer";
import ltUserReducer from "./ltUserReducer";
import CompanyReducer from "./companyReducer";
import driversReducer from "./driversReducer";
import EldProviderReducer from "./eldProvider";
import vehiclesReducer from "./vehiclesReducer";
import CommodityReducer from "./commodityReducer";
import dispatcherReducer from "./dispatcherReducer";
import notificationsReducer from "./notificationsReducer";
import EndorsementReducer from "./endorsementReducer";
import sidebarReducer from "./sidebarReducer";

const rootReducer = combineReducers({
  tab: TabReducer,
  auth: AuthReducer,
  ltUser: ltUserReducer,
  policy: policyReducer,
  company: CompanyReducer,
  drivers: driversReducer,
  vehicles: vehiclesReducer,
  quoteReducer: QuoteReducer,
  tab: TabReducer,
  endorsement: EndorsementReducer,
  commodities: CommodityReducer,
  dispatcher: dispatcherReducer,
  eldProvider: EldProviderReducer,
  notifications: notificationsReducer,
  sideBarMenu: sidebarReducer,
});

export default rootReducer;
