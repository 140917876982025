import {
  SET_COMPANY_USERS,
  UPDATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  ADD_NEW_COMPANY_USER,
  SAVE_USER_DETAILS,
  SET_COMPANY_LOSS_RUNS_DOCUMENTS,
  SET_COMPANY_DISPATCHERS,
  UPDATE_COMPANY_DISPATCHER,
  SET_COMPANY_DETAILS,
  SET_NOTES_FLAG,
  UPDATE_NOTES_IN_COMPANY,
} from "../Types";

export const setCompanyUsersAction = (users) => ({
  type: SET_COMPANY_USERS,
  payload: users,
});

export const updateCompanyUserAction = (CompanyUser) => ({
  type: UPDATE_COMPANY_USER,
  payload: CompanyUser,
});

export const addNewCompanyUser = (CompanyUser) => ({
  type: ADD_NEW_COMPANY_USER,
  payload: CompanyUser,
});

export const removeCompanyUserAction = (CompanyUser) => ({
  type: DELETE_COMPANY_USER,
  payload: CompanyUser,
});

export const saveUserDetailsAction = (data) => ({
  type: SAVE_USER_DETAILS,
  payload: data,
});

export const setCompanyLossRunsDocuments = (data) => ({
  type: SET_COMPANY_LOSS_RUNS_DOCUMENTS,
  payload: data,
});

export const setCompanyDispatchers = (data) => ({
  type: SET_COMPANY_DISPATCHERS,
  payload: data,
});

export const updateCompanyDispatcher = (data) => ({
  type: UPDATE_COMPANY_DISPATCHER,
  payload: data,
});

export const setCompanyDetails = (data) => ({
  type: SET_COMPANY_DETAILS,
  payload: data,
});
export const setNotesFlag = (data) => ({
  type: SET_NOTES_FLAG,
  payload: data,
});
export const updateNotesInCompany = (data) => ({
  type: UPDATE_NOTES_IN_COMPANY,
  payload: data,
});
