import { SET_DASHBOARD_TAB_INDEX, SET_DOCUMENTS_TAB_INDEX, SET_SETTINGS_TAB_INDEX } from "../Types";

export const setDashboardTabIndex = (tabIndex) => ({
  type: SET_DASHBOARD_TAB_INDEX,
  payload: tabIndex,
});

export const setDocumentTabIndex = (tabIndex) => ({
  type: SET_DOCUMENTS_TAB_INDEX,
  payload: tabIndex,
});

export const setSettingTabIndex = (tabIndex) => ({
  type: SET_SETTINGS_TAB_INDEX,
  payload: tabIndex,
});
