import React from "react";

export default function Badge({
  counter,
  top = "",
  right = "",
  bottom = "",
  color = "bg-counterColor",
  left = "",
  absolute = false,
}) {
  return (
    <div
      className={` rounded-full w-5 h-5 p-badge ${color} text-center font-PoppinsSemiBold text-xs  text-white ${
        absolute ? `absolute ${top} ${bottom} ${left} ${right}` : ""
      }`}
    >
      {counter}
    </div>
  );
}
