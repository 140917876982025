import moment from "moment-mini";

export const setDriversUtils = (state, payload) => {
  const _drivers = [...payload];
  const notPendingDrivers = _drivers.filter(
    (v) =>
      v.status != "pending" &&
      v.status != "pending-add" &&
      v.status != "pending-remove" &&
      v.status != "pending-restore"
  );
  const activeDrivers = notPendingDrivers.filter(
    (v) => !v.isDeleted && v.status == "clear"
  );
  const pendingDrivers = _drivers.filter(
    (v) =>
      v.status == "pending" ||
      v.status == "pending-add" ||
      v.status == "pending-remove" ||
      v.status == "pending-restore"
  );
  const pastDrivers = notPendingDrivers.filter((v) => v.isDeleted);

  return {
    ...state,
    drivers: [...activeDrivers],
    pendingDrivers: [...pendingDrivers],
    pastDrivers: [...pastDrivers],
  };
};

export const updateDrivers = (drivers, toBeUpdate) => {
  const index = drivers.findIndex((d) => d.id === toBeUpdate.id);
  if (index > -1) {
    drivers[index] = toBeUpdate;
  }
  return drivers;
};

export const updateDriverMVRStatus = (drivers, data) => {
  const index = drivers.findIndex((d) => d.id == data.payload.driverId);
  if (index > -1) {
    drivers[index]["mvrStatus"] = data.payload.mvrStatus;
    drivers[index]["mvrCheckDate"] = data.payload.mvrCheckDate
      ? moment(new Date(data.payload.mvrCheckDate)).format("MM/DD/YYYY")
      : null;
  }
  return [...drivers];
};

export const restoreDriverHandler = (state, payloadData) => {
  const payload = payloadFormation(payloadData);
  const _pendingDrivers = [...state.pendingDrivers];
  const _normalDrivers = [...state.drivers];
  let _pastDrivers = [...state.pastDrivers];
  _pastDrivers = _pastDrivers.filter((d) => d.id !== payload.id);
  if (payloadData.endorsment.length > 0) {
    _pendingDrivers.push(payload);
  } else {
    _normalDrivers.push(payload);
  }
  let _driversName = [
    ..._pendingDrivers,
    ..._normalDrivers,
    ..._pastDrivers,
  ].map((driver) => `${driver.firstName} ${driver.lastName}`);
  return {
    ...state,
    drivers: _normalDrivers,
    pastDrivers: _pastDrivers,
    pendingDrivers: _pendingDrivers,
    driversName: _driversName,
  };
};

export const addNewDriverHandler = (state, newDriver) => {
  // if new driver is endorsement then add in pending drivers else add to drivers list.
  const _drivers = [...state.drivers];
  const _pendingDrivers = [...state.pendingDrivers];
  if (newDriver.endorsment.length > 0) {
    _pendingDrivers.push(newDriver);
    return { ...state, pendingDrivers: [..._pendingDrivers] };
  } else {
    _drivers.push(newDriver);
    return { ...state, drivers: [..._drivers] };
  }
};

export const deleteDriverHandler = (state, deletedDriver) => {
  const _drivers = [...state.drivers];
  const _pendingDrivers = [...state.pendingDrivers];
  if (deletedDriver.endorsment.length > 0) {
    _pendingDrivers.push(deletedDriver);
    return {
      ...state,
      pendingDrivers: [..._pendingDrivers],
      drivers: deleteDriver([...state.drivers], deletedDriver),
    };
  } else {
    _drivers.push(deletedDriver);
    return {
      ...state,
      drivers: deleteDriver([...state.drivers], deletedDriver),
      pastDrivers: updatePastDrivers([...state.pastDrivers], deletedDriver),
    };
  }
};

const deleteDriver = (drivers, toBeDeleted) => {
  return drivers.filter((d) => d.id !== toBeDeleted.id);
};

const updatePastDrivers = (drivers, toBeUpdate) => {
  const newDeletedDriver = drivers.filter((d) => d.id === toBeUpdate.id);
  if (newDeletedDriver.length == 0) {
    drivers.push(toBeUpdate);
  }
  return drivers;
};

const payloadFormation = (data) => {
  const payload = { ...data };
  payload["name"] = `${payload.firstName} ${payload.lastName}`;
  payload["sdob"] =
    payload.dob !== ""
      ? moment(new Date(payload.dob)).format("MM/DD/YYYY")
      : null;
  payload["mvrCheckDate"] = payload.mvrCheckDate
    ? moment(new Date(payload.mvrCheckDate)).format("MM/DD/YYYY")
    : null;
  return payload;
};
