import React from "react";
import Notes from "../Notes";
import { useRouter } from "next/router";
import { CLEAR_DATA, SET_LT_SIDEBAR, SET_SIDEBAR } from "../../Redux/Types";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { setNotesFlag } from "../../Redux/Actions/companyActions";

const LTLoggedInUserBar = ({ openSidebar, LTuser }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { notesFlag } = useSelector((state) => state.company);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 481px)" });

  const setNotes = (value) => dispatch(setNotesFlag(value));
  const onExitAccess = () => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("mainToken");
      if (token) {
        localStorage.removeItem("mainToken");
        router.push(process.env.NEXT_PUBLIC_LT_TEAM_COMPANIES);
        dispatch({
          type: CLEAR_DATA,
        });
        dispatch({
          type: SET_SIDEBAR,
        });
        dispatch({
          type: SET_LT_SIDEBAR,
        });
      }
    }
  };
  return (
    <>
      <header
        className={
          isTabletOrMobile
            ? ` bg-bgActiveButton sticky top-16 sm:h-9 xs:h-11 flex flex-row   items-center justify-between  uppercase z-10 shadow-md duration-300 px-2 lg:text-base   xl:text-base text-white font-hkBold xs:font-hkMedium xs:text-xs`
            : `bg-bgActiveButton sticky top-16 sm:h-9 xs:h-11 flex flex-row ${
                openSidebar ? "pl-72" : "pl-32"
              }  items-center xs:justify-center   custom:text-xs custom5:text-xs sm:justify-between  md:justify-between  uppercase z-10 shadow-md duration-300 pr-20 xs:pr-4 gap-x-4 xs:pl-0 md:text-sm lg:text-base xs:text-sm  xl:text-base text-white  font-hkBold xs:font-hkMedium`
        }
      >
        <div>{`BROKER VIEW: ${LTuser?.name}`}</div>
        <div>{`LOGGED IN AS ${user?.company.name}`}</div>
        <div className="flex gap-10 relative">
          <div
            onClick={() => {
              setNotes(notesFlag == null ? true : !notesFlag);
            }}
            className=" flex flex-row items-center justify-center space-x-2 cursor-pointer "
          >
            <h4 className="text-center"> NOTES</h4>
          </div>
          {notesFlag && <Notes setNotes={setNotes} notesFlag={notesFlag} />}
          <div onClick={onExitAccess} className=" flex flex-row items-center justify-center space-x-2 cursor-pointer">
            <h4 className="text-center"> EXIT ACCESS</h4>
          </div>
        </div>
      </header>
    </>
  );
};

export default LTLoggedInUserBar;
