import React, { useEffect } from "react";
import Head from "next/head";
import Script from "next/script";
import { Provider } from "react-redux";
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";
import { useMutation } from "@apollo/client";
import "react-toastify/dist/ReactToastify.css";
import "../lt-dashboard/assets/css/global.css";
import Router, { useRouter } from "next/router";
import { ToastContainer } from "react-toastify";
import "../lt-dashboard/assets/css/Calendar.css";
import "../lt-dashboard/assets/css/DatePicker.css";
import { GTMPageView } from "../lt-dashboard/utils/gtm";
import { withApollo } from "../lt-dashboard/lib/withApollo";
import { PersistGate } from "redux-persist/integration/react";
import LandingLayout from "../components/Layouts/LandingLayout";
import Layout1 from "../lt-dashboard/components/Layouts/Layout1";
import Layout2 from "../lt-dashboard/components/Layouts/Layout2";
import Layout3 from "../lt-dashboard/components/Layouts/Layout3";
import Layout4 from "../lt-dashboard/components/Layouts/Layout4";
import Layout404 from "../lt-dashboard/components/Layouts/Layout404";
import store, { persistor } from "../lt-dashboard/Redux/Reducers/store";
import { ERROR_MESSAGE_TO_SLACK } from "../lt-dashboard/pages/api/mutations";

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY);
const layouts = {
  L1: Layout1,
  L2: Layout2,
  L3: Layout3,
  L4: Layout4,
  L404: Layout404,
  LL: LandingLayout,
};

import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }) {
  console.log("error", error);

  return (
    <div className=" bg-landing min-h-screen flex-col flex justify-center items-center">
      <div className="flex items-center cursor-pointer">
        {process.env.NEXT_PUBLIC_THEME === "LT" ? (
          <>
            <img
              src="/Images/logo-circle.webp"
              width={"80px"}
              height={"80px"}
              className="rounded-full"
            />
            <img
              src="/Images/wordmark-luckytruck.webp"
              width={"300px"}
              height={"100px"}
              className="rounded-full"
            />
          </>
        ) : (
          <img
            src="/Images/flexport/Logo-flexport.webp"
            width={"180px"}
            height={"36px"}
          />
        )}
      </div>
      <p className=" font-hkMedium text-lg">Something went wrong</p>
      {process.env.NEXT_PUBLIC_NODE_ENV !== "production" ? (
        <pre>{error.message}</pre>
      ) : null}
      😞😞😞😞😞😞
    </div>
  );
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const handleRouteChange = (url) => GTMPageView(url);
  const [errorMessageToSlack] = useMutation(ERROR_MESSAGE_TO_SLACK);
  // const { LTuser } = useSelector((state) => state.ltUser);
  Router.onRouteChangeComplete = (url) => {
    if (
      typeof window !== "undefined" &&
      process.env.NEXT_PUBLIC_NODE_ENV == "production"
    ) {
      handleRouteChange(url);
    }
  };

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      process.env.NEXT_PUBLIC_NODE_ENV == "production"
    ) {
      TagManager.initialize({ gtmId: "GTM-MLP8N46" });
      return () => {
        Router.onRouteChangeComplete = (url) => {
          handleRouteChange(url);
        };
      };
    }
  }, []);
  const Layout = layouts[Component.layout] || ((children) => <>{children}</>);

  useEffect(() => {
    // Normal user Dashboard flow
    if (Component.layout === "L1") {
      const mainToken = localStorage.getItem("mainToken");
      const token = localStorage.getItem("token");
      let withOutDot = localStorage.getItem("withOutDot");
      if (!mainToken && !withOutDot && !token) {
        router.push("/Auth/Signup");
      }
    }

    // Login signups page
    if (Component.layout === "L2") {
      const mainToken = localStorage.getItem("mainToken");
      const LTtoken = localStorage.getItem("LTtoken");
      const DispatcherToken = localStorage.getItem("DispatcherToken");
      if (mainToken) {
        router.replace("/Dashboard");
      } else if (LTtoken) {
        const identifyOldUserSession = localStorage.getItem("LTuserData");

        if (identifyOldUserSession) {
          localStorage.clear();
          router.replace("/Auth/LTteamLogin");
        } else {
          router.replace("/LTteam/LTHome");
        }
      } else if (DispatcherToken) {
        router.replace("/Dispatcher/Home");
      }
    }

    // LT broker dashboard
    if (Component.layout === "L3") {
      const mainToken = localStorage.getItem("mainToken");
      const LTtoken = localStorage.getItem("LTtoken");

      if (mainToken && LTtoken) {
        router.replace("/Dashboard");
        return;
      } else if (!LTtoken) {
        router.replace("/Auth/LTteamLogin");
      }
    }

    // Dispatcher dashboard
    if (Component.layout === "L4") {
      const DispatcherToken = localStorage.getItem("DispatcherToken");
      if (!DispatcherToken) {
        router.replace("/Auth/dispatcherLogin");
      }
    }

    // restricting Landing pages for flexport
    if (Component.layout === "LL" && process.env.NEXT_PUBLIC_THEME !== "LT") {
      router.push("/Auth/Signup");
      return;
    }
  }, [router.pathname]);

  const onError = async (error) => {
    if (process.env.NEXT_PUBLIC_NODE_ENV === "production") {
      const mainToken = localStorage.getItem("mainToken");

      try {
        await errorMessageToSlack({
          variables: {
            normalUser: mainToken ? true : false,
            message: `${error} Error Occurred at path:${router.pathname}`,
            ltUserName: "",
          },
        });
      } catch (error) {
        console.log("Catch Error", error);
      }
    } else {
      console.log("Catch Error", error);
    }
  };

  return (
    <Provider store={store}>
      <Head>
        <title>
          {process.env.NEXT_PUBLIC_THEME === "LT"
            ? "Trucking Insurance Provider | LuckyTruck"
            : "Trucking Insurance Provider | Flexport"}
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta
          name="facebook-domain-verification"
          content="rh88eyb15od9lwjoff0jsoalmi80nh"
        />
        {/* gtm */}
        {typeof window !== "undefined" &&
          process.env.NEXT_PUBLIC_NODE_ENV == "production" && (
            <>
              {/* <link rel="preconnect" href="https://www.googletagmanager.com" /> */}

              {/* <script
                type="text/partytown"
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-MLP8N46');`,
                }}
              /> */}
              <Script
                src="https://www.googletagmanager.com/gtm.js?id=GTM-MLP8N46"
                strategy="lazyOnload"
              />
            </>
          )}
        <script
          defer
          src="https://cdn.jsdelivr.net/npm/pdfjs-dist@2.13.216/build/pdf.min.js"
        />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "LuckyTruck",
            "url": "https://www.luckytruck.com/",
            "logo": "https://www.luckytruck.com/Images/wordmark-luckytruck.webp",
            "sameAs": [
              "https://www.facebook.com/LuckyTruckPlatform",
              "https://www.luckytruck.com/Images/linkedin.png",
              "https://www.luckytruck.com/"
            ]
          }
        `}</script>
        <link rel="canonical" href="https://luckytruck.com/" />
        {/* <link
          rel="preload"
          href={riveWasmUrl}
          as="fetch"
          crossOrigin="anonymous"
        /> */}
        ;{/* scripts end */}
        {/* pwa meta tags */}
        <meta name="application-name" content="LuckyTruck" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        {/* <meta
          name="google-site-verification"
          content="JDDw_AaFgHPX_e5srcC9FC1Wno5xeW40653vHsuLYNw"
        /> */}
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="LuckyTruck" />
        <meta
          name="description"
          content="LuckyTruck | We're here to help! Buy commercial trucking insurance and manage it through one seamless platform. Get started now"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        {/* <meta name="msapplication-config" content="/icons/browserconfig.xml" /> */}
        <meta name="msapplication-TileColor" content="#2B5797" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#5468FF" />
        {/* <link rel="apple-touch-icon" href="/icons/touch-icon-iphone.png" /> */}
        {/* <link rel="manifest" href="/manifest.json" /> */}
        {/* <link rel='mask-icon' href='/icons/safari-pinned-tab.svg' color='#5bbad5' /> */}
        {/* Setting favicon for flexport conditionally */}
        {process.env.NEXT_PUBLIC_THEME === "LT" ? (
          <>
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/Images/logo152x152.png"
            />

            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/Images/logo180x180.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="167x167"
              href="/Images/logo167x167.png"
            />

            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/Images/logo32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/Images/logo16x16.png"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="icon" href="/favicon.ico" />
            {/* <link rel="manifest" href="/manifest.json" /> */}
          </>
        ) : (
          <>
            <link rel="shortcut icon" href="/favicon-flexport.ico" />
            <link rel="icon" href="/favicon-flexport.ico" />
            {/* <link rel="manifest" href="/manifest.json" /> */}
          </>
        )}
        {/* <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500' /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="LuckyTruck" />
        <meta
          property="og:description"
          content="LuckyTruck | We're here to help! Buy commercial trucking insurance and manage it through one seamless platform. Get started now"
        />
        <meta property="og:site_name" content="LuckyTruck" />
        <meta
          property="og:url"
          content={process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}/apple-touch-icon.png`}
        />
        {/* pwa meta tags end */}
      </Head>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
          <Layout>
            <ToastContainer
              autoClose={5000}
              bodyClassName={"font-hkBold text-inputColor"}
            />

            <Component {...pageProps} />
          </Layout>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

export default withApollo()(MyApp);
